<template>
  <div id="app">
    <div id="nav">
      <div class="nav-left">
        <div v-on:click="openHome" style="cursor: pointer">
          <h1>KHARISMA <span>AGRI INOVASI</span></h1>
        </div>
        <div v-on:click="open" class="open">
          <i class="fa fa-reorder" style="font-size:24px; color: green;"></i>
        </div>
      </div>
      <div class="nav-right">
        <ul>
          <a href="/"><li id="main_men" >Beranda</li></a>
          <a href="/artikel"><li id="main_men">Artikel</li></a>
          <a href="#"><li class="dropdown">
            Informasi Agrikultur <i style="margin-left: 10px;" class="fa fa-caret-down"></i>
            <ul class="dropdown-menu">
              <a href="/agri/tanaman"><li>Tanaman</li></a>
              <a href="/agri/hama"><li>Hama</li></a>
            </ul>
          </li></a>
          <a href="/tentang"><li id="main_men">Tentang Kami</li></a>
          <!-- <li>EN</li> -->
        </ul>
      </div>
    </div>
    <div>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    openHome() {
      this.$router.push("/");
    },
    open(){
      var elements = document.getElementsByClassName('nav-right');
      for(var i=0; i<elements.length; i++) {
        elements[i].style.display = elements[i].style.display == 'block' ? 'none' : 'block';
      }

    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
#nav {
  width: 100%;
  padding: 0 2em;
  margin: 0;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  position: fixed;
  box-shadow: 1px 8px 6px -6px rgb(116, 116, 116);
  z-index: 1000;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.nav-left {
  width: 40%;
  padding: 1.25em 0;
}
.nav-left h1 {
  color: #006837;
  font-size: 1.5rem;
}
.nav-left span {
  color: #006837;
  font-size: 1.5rem;
  font-weight: 400;
}
.nav-right {
  width: 60%;
  height: 100%;
}
.nav-right ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.nav-right ul li {
  list-style: none;
  padding: 1.5em 0.75em;
  transition: border-bottom 0.25s ease-out;
  color: #006837;
  font-size: 1rem;
  text-decoration: none;
}
.nav-right ul #main_men:hover {
  border-bottom: 5px solid #006837;
  padding-bottom: calc(1.5em - 5px);
}
.nav-right a {
  text-decoration: none;
}
#nav .open{
  padding: 0.5em 1.2em;
  height: fit-content;
  border: 1px solid green;
  border-radius: 5px;
  display: none;
  cursor: pointer;
}
#nav .open:hover{
  transform: scale(1.05);
}
.dropdown ul {
  display: none;
  width: 225px;
  transition: display 2s ease-in;
}
.dropdown:hover ul {
  display: block;
}
.dropdown-menu {
  position: absolute;
  margin-left: -15px;
  margin-top: 1.2em;
  box-shadow: 1px 8px 6px -6px rgb(116, 116, 116);
  z-index: 1;
}
.dropdown-menu li {
  background-color: white;
  transition: background-color 1.5s ease;
}
.dropdown-menu li:hover {
  background-color: green;
  color: white;
  border-bottom: none;
}
@media only screen and (min-width: 850px){
  .nav-right{
    display: block !important;
  }
}
@media only screen and (max-width: 550px){
  .nav-left{
    padding-left: 0;
  }
  .nav-left h1 {
    transform: scale(0.85);
    margin-left: -1em;
  }
  .nav-left .open {
    transform: scale(0.85);
  }
}
@media only screen and (max-width: 400px){
  #nav{
    height: 72px;
  }
  .nav-left h1 {
    transform: scale(0.80);
    margin-left: -1em;
  }
  .nav-left .open {
    transform: scale(0.80);
  }
}
@media only screen and (max-width: 849.9px){
  #nav{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #nav .open{
    display: flex;
  }
  .nav-right{
    display: none;
    width: 300px;
    padding: 0;
    padding-left: 1em;
    background: white;
    position: absolute;
    right: 0;
    margin-top: 66px;
    height: 100vh;
  }
  .nav-right ul{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .nav-right ul #main_men:hover {
    border-bottom: none;
    padding-bottom: 12.5px;
    font-weight: bold;
  }
  .nav-right ul li {
    transition: none;
    padding: 0.8em 0.5em;
  }
  .nav-left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em 0;
  }
  .dropdown ul {
    display: block;
    width: 225px;
    transition: none;
  }
  .dropdown:hover ul {
    display: block;
  }
  .dropdown-menu {
    position: relative;
    margin-left: 0px;
    margin-top: 0em;
    box-shadow: none;
    z-index: 0;
    margin-bottom: 1em;
  }
  .dropdown-menu li {
    background-color: none;
    transition:none;
    color: grey !important;
  }
  .dropdown-menu li{
    margin-top: 1em;
    margin-bottom: -1em;
  }
  .dropdown-menu li:hover {
    background-color: white;
    color: grey;
    border-bottom: none;
    font-weight: bold;
  }
  .fa-caret-down{
    display: none;
  }
}
</style>
