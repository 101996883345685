import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    /** Home */
    {
        path: '/',
        name: 'Home',
        component: () => import('../components/Home/Home.vue'),
        meta: {
            title: 'Kharisma Agri Inovasi'
        },
    },
    {
        path: '/agri/hama',
        name: 'AgriInfoPest',
        component: () => import('../components/AgriInfo/AgriInfoPest.vue'),
        meta: {
            title: 'Informasti Agrikultur | Hama'
        }
    },
    {
        path: '/agri/tanaman',
        name: 'AgriInfoPlant',
        component: () => import('../components/AgriInfo/AgriInfoPlant.vue'),
        meta: {
            title: 'Informasti Agrikultur | Tanaman'
        }
    },
    {
        path: '/tentang',
        name: 'About',
        component: () => import('../components/About/About.vue'),
        meta: {
            title: 'Tentang Kami'
        }
    },
    {
        path: '/agri/tanaman/:nama',
        name: 'PlantInfo',
        component: () => import('../components/Info/PlantInfo.vue'),
        meta: {
            title: `Informasi Agrikultur`
        }
    },
    {
        path: '/agri/hama/:nama',
        name: 'HamaInfo',
        component: () => import('../components/Info/PestInfo.vue'),
        meta: {
            title: `Informasi Agrikultur`
        }
    },
    {
        path: '/artikel',
        name: 'Artikel',
        component: () => import('../components/Artikel/ArtikelView.vue'),
        meta: {
            title: `Artikel`
        }
    },
    {
        path: '/artikel/:nama',
        name: 'ArtikelDetail',
        component: () => import('../components/Artikel/ArtikelDetail.vue'),
        meta: {
            title: `Artikel`
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

export default router
